<template>
  <div>
    <div
      width="100%"
      style="
        background: rgb(236, 239, 244);
        padding: 50px 20px;
        color: rgb(81, 77, 106);
        border-radius: 5px;
      "
    >
      <div style="max-width: 700px; margin: 0px auto; font-size: 14px">
        <table
          cellpadding="0"
          cellspacing="0"
          style="width: 100%; margin-bottom: 20px; border: 0px"
        >
          <tbody>
            <tr>
              <td style="vertical-align: top">
                <h4>
                  <img
                    src="/logo.svg"
                    alt="Visual Vuilder"
                    style="height: 40px; margin-right: 10px"
                  /><strong>Visual Builder</strong>
                </h4>
              </td>
              <td style="text-align: right; vertical-align: middle">
                <span style="color: rgb(160, 155, 185)">Some Description</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="padding: 40px 40px 20px; background: rgb(255, 255, 255)">
          <table cellpadding="0" cellspacing="0" style="width: 100%; border: 0px">
            <tbody>
              <tr>
                <td>
                  <div
                    style="
                      padding: 15px 30px;
                      background: rgb(70, 190, 138);
                      border-radius: 5px;
                      margin-bottom: 20px;
                      color: rgb(255, 255, 255);
                    "
                  >
                    Success! Something good happened.
                  </div>
                  <div
                    style="
                      padding: 15px 30px;
                      background: rgb(251, 67, 74);
                      border-radius: 5px;
                      margin-bottom: 20px;
                      color: rgb(255, 255, 255);
                    "
                  >
                    Error! Something bad happened.
                  </div>
                  <div
                    style="
                      padding: 15px 30px;
                      background: rgb(255, 255, 255);
                      border: 1px solid rgb(172, 183, 191);
                      border-radius: 5px;
                      margin-bottom: 20px;
                    "
                  >
                    Information! Something neutral happened.
                  </div>
                  <p>Hi George,</p>
                  <p>Congratulations! Something good has appened.</p>
                  <div style="text-align: center">
                    <a
                      style="
                        display: inline-block;
                        padding: 11px 30px 6px;
                        margin: 20px 0px 30px;
                        font-size: 15px;
                        color: rgb(255, 255, 255);
                        background: rgb(1, 168, 254);
                        border-radius: 5px;
                      "
                      >Take Action Now</a
                    >
                  </div>
                  <p>Thanks for being great customer. Let it be!</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style="
            text-align: center;
            font-size: 12px;
            color: rgb(160, 155, 185);
            margin-top: 20px;
          "
        >
          <p>
            Sellpixels.com LTD., Abbey Road, San Francisco CA 94102<br />Don't like these emails?
            <a style="color: rgb(160, 155, 185); text-decoration: underline">Unsubscribe</a
            ><br />Powered by Visaul Builder
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
