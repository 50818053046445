<template>
  <div>
    <div
      style="
        width: 100%;
        background: rgb(236, 239, 244);
        padding: 50px 20px;
        color: rgb(81, 77, 106);
        border-radius: 5px;
      "
    >
      <div style="max-width: 700px; margin: 0px auto; font-size: 14px">
        <table
          cellpadding="0"
          cellspacing="0"
          style="width: 100%; margin-bottom: 20px; border: 0px"
        >
          <tbody>
            <tr>
              <td style="vertical-align: top">
                <h4>
                  <img
                    src="/logo.svg"
                    alt="Visual Vuilder"
                    style="height: 40px; margin-right: 10px"
                  /><strong>Visual Builder</strong>
                </h4>
              </td>
              <td style="text-align: right; vertical-align: middle">
                <span style="color: rgb(160, 155, 185)">Some Description</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="padding: 40px 40px 20px; background: rgb(255, 255, 255)">
          <table cellpadding="0" cellspacing="0" style="width: 100%; border: 0px">
            <tbody>
              <tr>
                <td>
                  <h2
                    style="
                      margin-bottom: 20px;
                      color: rgb(36, 34, 47);
                      font-weight: 600;
                    "
                  >
                    Thanks for Purchase!
                  </h2>
                  <p>
                    <span style="color: rgb(160, 155, 185)">Monday, Dec 28 2015 at 4:13 PM</span>
                  </p>
                  <br />
                  <h5
                    style="
                      margin-bottom: 20px;
                      color: rgb(36, 34, 47);
                      font-weight: 600;
                    "
                  >
                    Your Order #00002345
                  </h5>
                  <table cellpadding="0" cellspacing="0" style="width: 100%; border: 0px">
                    <tbody>
                      <tr>
                        <td
                          style="
                            text-align: left;
                            padding: 10px 10px 10px 0px;
                            border-top: 3px solid rgb(81, 77, 106);
                          "
                        >
                          Apple iPhone 6S
                        </td>
                        <td
                          style="
                            width: 10%;
                            text-align: center;
                            padding: 10px;
                            border-top: 3px solid rgb(81, 77, 106);
                          "
                        >
                          1
                        </td>
                        <td
                          style="
                            width: 20%;
                            text-align: right;
                            padding: 10px 0px 10px 10px;
                            white-space: nowrap;
                            border-top: 3px solid rgb(81, 77, 106);
                          "
                        >
                          $ 699.00
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            text-align: left;
                            padding: 10px 10px 10px 0px;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          Data cable
                        </td>
                        <td
                          style="
                            width: 10%;
                            text-align: center;
                            padding: 10px;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          1
                        </td>
                        <td
                          style="
                            width: 20%;
                            text-align: right;
                            padding: 10px 0px 10px 10px;
                            white-space: nowrap;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          $ 9.98
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            text-align: left;
                            padding: 10px 10px 10px 0px;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          Nueng Silver Case
                        </td>
                        <td
                          style="
                            width: 10%;
                            text-align: center;
                            padding: 10px;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          2
                        </td>
                        <td
                          style="
                            width: 20%;
                            text-align: right;
                            padding: 10px 0px 10px 10px;
                            white-space: nowrap;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          $ 17.49
                        </td>
                      </tr>
                      <tr style="color: rgb(160, 155, 185)">
                        <td
                          style="
                            text-align: left;
                            padding: 10px 10px 10px 0px;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          Subtotal
                        </td>
                        <td
                          style="
                            width: 10%;
                            text-align: center;
                            padding: 10px;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          4
                        </td>
                        <td
                          style="
                            width: 20%;
                            text-align: right;
                            padding: 10px 0px 10px 10px;
                            white-space: nowrap;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          $ 735.96
                        </td>
                      </tr>
                      <tr style="color: rgb(160, 155, 185)">
                        <td
                          style="
                            text-align: left;
                            padding: 0px 10px 10px 0px;
                            border-top: 0px solid rgb(217, 215, 224);
                          "
                        >
                          Tax
                        </td>
                        <td
                          style="
                            width: 10%;
                            text-align: center;
                            padding: 0px 10px;
                            border-top: 0px solid rgb(217, 215, 224);
                          "
                        >
                          10%
                        </td>
                        <td
                          style="
                            width: 20%;
                            text-align: right;
                            padding: 0px 0px 10px 10px;
                            white-space: nowrap;
                            border-top: 0px solid rgb(217, 215, 224);
                          "
                        >
                          $ 73.60
                        </td>
                      </tr>
                      <tr style="color: rgb(160, 155, 185)">
                        <td
                          style="
                            text-align: left;
                            padding: 0px 10px 10px 0px;
                            border-top: 0px solid rgb(217, 215, 224);
                          "
                        >
                          Shipping
                        </td>
                        <td
                          style="
                            width: 10%;
                            text-align: center;
                            padding: 0px 10px;
                            border-top: 0px solid rgb(217, 215, 224);
                          "
                        >
                          &nbsp;
                        </td>
                        <td
                          style="
                            width: 20%;
                            text-align: right;
                            padding: 0px 0px 10px 10px;
                            white-space: nowrap;
                            border-top: 0px solid rgb(217, 215, 224);
                          "
                        >
                          $ 9.99
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            text-align: left;
                            padding: 10px 10px 10px 0px;
                            border-top: 3px solid rgb(81, 77, 106);
                          "
                        >
                          <span style="font-size: 18px; font-weight: bold">Total</span>
                        </td>
                        <td
                          style="
                            width: 10%;
                            text-align: center;
                            padding: 10px;
                            border-top: 3px solid rgb(81, 77, 106);
                          "
                        ></td>
                        <td
                          style="
                            width: 20%;
                            text-align: right;
                            padding: 10px 0px 10px 10px;
                            white-space: nowrap;
                            border-top: 3px solid rgb(81, 77, 106);
                          "
                        >
                          <span style="font-size: 18px; font-weight: bold">$ 876.96</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                  <h5
                    style="
                      margin-bottom: 20px;
                      color: rgb(36, 34, 47);
                      font-weight: 600;
                    "
                  >
                    Your Details
                  </h5>
                  <table cellpadding="0" cellspacing="0" style="width: 100%; border: 0px">
                    <tbody>
                      <tr>
                        <td
                          style="
                            text-align: left;
                            padding: 10px 10px 10px 0px;
                            border-top: 1px solid rgb(217, 215, 224);
                            white-space: nowrap;
                            vertical-align: top;
                          "
                        >
                          Shipping To
                        </td>
                        <td
                          style="
                            width: 50%;
                            padding: 10px 0px 10px 10px;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          Tony Stark<br />22 23rd Street<br />San Francisco<br />CA 94107
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            text-align: left;
                            padding: 10px 10px 10px 0px;
                            border-top: 1px solid rgb(217, 215, 224);
                            white-space: nowrap;
                            vertical-align: top;
                          "
                        >
                          Billed To
                        </td>
                        <td
                          style="
                            width: 50%;
                            padding: 10px 0px 10px 10px;
                            border-top: 1px solid rgb(217, 215, 224);
                          "
                        >
                          Visa<br />Ending in *7643<br />Expiring 08/2020
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br /><br />
                  <p style="text-align: center">
                    Notice something wrong?
                    <a
                      style="
                        color: rgb(1, 168, 254);
                        text-decoration: underline;
                      "
                      >Contact our support team</a
                    >
                    and we'll e happy to help.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style="
            text-align: center;
            font-size: 12px;
            color: rgb(160, 155, 185);
            margin-top: 20px;
          "
        >
          <p>
            Sellpixels.com LTD., Abbey Road, San Francisco CA 94102<br />Don't like these emails?
            <a style="color: rgb(160, 155, 185); text-decoration: underline">Unsubscribe</a
            ><br />Powered by Visual Builder
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
