<template>
  <div>
    <div
      style="
        background: rgb(236, 239, 244);
        padding: 50px 20px;
        color: rgb(81, 77, 106);
        border-radius: 5px;
      "
    >
      <div style="max-width: 700px; margin: 0px auto; font-size: 14px">
        <table
          cellpadding="0"
          cellspacing="0"
          style="width: 100%; margin-bottom: 20px; border: 0px"
        >
          <tbody>
            <tr>
              <td style="vertical-align: top">
                <h4>
                  <img
                    src="/logo.svg"
                    alt="Visual Vuilder"
                    style="height: 40px; margin-right: 10px"
                  /><strong>Visual Builder</strong>
                </h4>
              </td>
              <td style="text-align: right; vertical-align: middle">
                <span style="color: rgb(160, 155, 185)">Some Description</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="padding: 40px 40px 20px; background: rgb(255, 255, 255)">
          <table cellpadding="0" cellspacing="0" style="width: 100%; border: 0px">
            <tbody>
              <tr>
                <td>
                  <p>Hi there,</p>
                  <p>
                    Sometimes you just want to send a simple HTML email with a simple design and
                    clear call to action.
                  </p>
                  <a
                    style="
                      display: inline-block;
                      padding: 11px 30px 6px;
                      margin: 20px 0px 30px;
                      font-size: 15px;
                      color: rgb(255, 255, 255);
                      background: rgb(1, 168, 254);
                      border-radius: 5px;
                    "
                    >Call To Action</a
                  >
                  <p>
                    This is a really simple email template. It's sole purpose is to get the
                    recipient to click the button with no distractions.
                  </p>
                  <p>Good luck! Hope it works.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style="
            text-align: center;
            font-size: 12px;
            color: rgb(160, 155, 185);
            margin-top: 20px;
          "
        >
          <p>
            Sellpixels.com LTD., Abbey Road, San Francisco CA 94102<br />Don't like these emails?
            <a style="color: rgb(160, 155, 185); text-decoration: underline">Unsubscribe</a
            ><br />Powered by Visual Builder
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
